import React from 'react';
import { Config } from 'types';
import { Telegram, GitHub, } from 'icons';

const config: Config = {
  name: {
    display: 'patwer',
    aria: 'My name is Artyom Ogoreltsev',
  },
  title: {
    display: 'System Administrator',
    aria: 'I am a System Administrator',
  },
  buttons: [
    {
      display: 'GitHub',
      aria: 'Visit my GitHub profile',
      icon: <GitHub />,
      href: 'https://github.com/patvver/',
    },
    {
      display: 'Telegram',
      aria: 'Send me an Telegram',
      icon: <Telegram />,
      href: 'https://t.me/pawanel',
    },
  ],
};

export default config;
